<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)" >
                  <b-row>
                    <b-col lg="10" sm="12">
                      <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="organization"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.org_id"
                            :options="organizationList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Lab" vid="lab_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="lab_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('component_settings.lab_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="formData.lab_id"
                            :options="labList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Name (En)" vid="name" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="name"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.name_en')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                              id="name"
                              v-model="formData.name"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Name (Bn)" vid="name_bn">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="name_bn"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.name_bn')}}
                          </template>
                          <b-form-input
                              id="name_bn"
                              v-model="formData.name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider name="Amount" vid="amount" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="amount"
                            slot-scope="{ valid, errors }"
                        >
                          <template v-slot:label>
                            {{$t('globalTrans.amount')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="amount"
                            v-model="formData.amount"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                      <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                      &nbsp;
                      <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                  </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { ingredientStore, ingredientUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  mixins: [commonAuth],
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        name: '',
        name_bn: '',
        lab_id: 0,
        amount: '',
        org_id: 0
      },
      labList: [],
      loading: false
    }
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  created () {
    if (this.$store.state.Auth.authUser.user_id !== 1) {
        this.formData.org_id = this.$store.state.Auth.authUser.org_id
    }
    if (this.id) {
      const tmp = this.getServiceData()
      this.formData = tmp
    }
  },
  watch: {
    'formData.org_id': function (newValue, oldVal) {
      if (newValue !== oldVal) {
        this.labList = this.getlab(newValue)
      }
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getServiceData () {
      let tmpData = this.$store.state.list.find(item => item.id === this.id)
      tmpData = Object.assign({}, tmpData)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getlab (orgId) {
      const labList = this.$store.state.licenseRegistration.commonObj.labList.filter(item => item.status === 1)
      if (orgId) {
        return labList.filter(item => item.org_id === orgId)
      }
      return labList
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${ingredientUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, ingredientStore, this.formData)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
      this.loading = false
    }
  }
}
</script>
